$(document).foundation();

$(function() {
    $('.menu-icon').on('click', function() {
        $('body').toggleClass('menuOpened');
    });

    // Footer newsletter
    var footerNewsletterForm = document.getElementById('footer-newsletter-form');
    var footerNewsletterSubmitButton = document.getElementById('footer-newsletter-submit');
    var footerNewsletterSuccessMessage = document.getElementById('footer-newsletter-sent');
    var footerNewsletterErrorMessage = document.getElementById('footer-newsletter-error');

    footerNewsletterForm.addEventListener('submit', function (e) {
        footerNewsletterSubmitButton.setAttribute('disabled', 'disabled');
        footerNewsletterSuccessMessage.classList.add('hide');
        footerNewsletterErrorMessage.classList.add('hide');
        e.preventDefault();

        let email = document.getElementById('footer-newsletter-email').value;
        axios.post('https://api.sendinblue.com/v3/contacts',
            {
                'email' : email,
                'listIds': [7],
                'updateEnabled' : true
            },
            {
            headers: {
                'accept':'application/json',
                'api-key':'xkeysib',
                'content-type':'application/json'
            },
        })
        .then(function (response) {
            footerNewsletterSubmitButton.removeAttribute('disabled');
            footerNewsletterSuccessMessage.classList.remove('hide');
            footerNewsletterForm.reset();
        })
        .catch(function (error) {
            footerNewsletterSubmitButton.removeAttribute('disabled');
            footerNewsletterErrorMessage.classList.remove('hide');
        })
    })
});